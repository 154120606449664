import { UserRole } from '@/shared/types/roles'
import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      roles: [UserRole.ROLE_USER],
    },
    component: () => import('./home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/user/login/index.vue'),
    meta: { layout: 'EmptyLayout' },
  },
  {
    path: '/sql',
    name: 'sql',
    component: () => import('@/pages/user/sql/index.vue'),
  },
  {
    path: '/reset-password/',
    name: 'reset-password',
    component: () => import('@/pages/user/restore-password/index.vue'),
    meta: { layout: 'EmptyLayout' },
  },
  {
    path: '/password_reset/',
    name: 'verify-password',
    component: () => import('@/pages/user/verify-password/index.vue'),
    meta: { layout: 'EmptyLayout' },
  },
  {
    path: '/applications/',
    name: 'applications',
    meta: {
      roles: [UserRole.ROLE_USER, UserRole.ROLE_APPLICATION_DESIGNER],
      permission: ['VIEW_APPLICATIONS'],
    },
    component: () => import('@/pages/applications-and-dashboards/applications/index.vue'),
  },
  {
    path: '/application/:guid',
    name: 'application',
    meta: {
      roles: [UserRole.ROLE_USER, UserRole.ROLE_APPLICATION_DESIGNER],
      permission: ['VIEW_APPLICATION'],
    },
    component: () => import('@/pages/applications-and-dashboards/application/index.vue'),
  },
  {
    path: '/dashboard/:guid',
    name: 'dashboard',
    meta: {
      roles: [UserRole.ROLE_USER, UserRole.ROLE_APPLICATION_DESIGNER],
      permission: ['VIEW_DASHBOARD'],
    },
    component: () => import('@/pages/applications-and-dashboards/dashboard/index.vue'),
  },
  {
    path: '/dashboard-embed/:guid',
    name: 'dashboard-embed',
    meta: {
      roles: [UserRole.ROLE_USER, UserRole.ROLE_APPLICATION_DESIGNER],
      permission: ['VIEW_DASHBOARD'],
    },
    component: () => import('@/pages/applications-and-dashboards/dashboard-embed/index.vue'),
  },
  {
    path: '/widget-embed',
    name: 'widget-embed',
    meta: { layout: 'EmptyLayout' },
    component: () => import('@/pages/applications-and-dashboards/widget-embed/index.vue'),
  },
  {
    path: '/widget-export',
    name: 'widget-export',
    meta: { layout: 'EmptyLayout' },
    component: () => import('@/pages/applications-and-dashboards/widget-embed/index.vue'),
  },
  {
    path: '/reporting/',
    meta: {
      role: [UserRole.ROLE_REPORT_DEVELOPER],
    },
    component: () => import('@/pages/reporting/index.vue'),
    children: [
      {
        path: 'library',
        name: 'reports-library',
        meta: {
          role: [UserRole.ROLE_REPORT_DEVELOPER],
        },
        component: () => import('@/pages/reporting/library/index.vue'),
      },
      {
        path: 'lists',
        name: 'mailing-lists',
        meta: {
          role: [UserRole.ROLE_REPORT_DEVELOPER],
        },
        component: () => import('@/pages/reporting/mailing-lists/index.vue'),
      },
      {
        path: 'tasks',
        name: 'mailing-tasks',
        meta: {
          role: [UserRole.ROLE_REPORT_DEVELOPER],
        },
        component: () => import('@/pages/reporting/mailing-tasks/index.vue'),
      },
      {
        path: 'task-instances',
        name: 'mailing-task-instances',
        meta: {
          role: [UserRole.ROLE_REPORT_DEVELOPER],
        },
        component: () => import('@/pages/reporting/mailing-task-instances/index.vue'),
      },
      {
        path: 'reports/:reportGuid',
        name: 'report',
        meta: {
          role: [UserRole.ROLE_REPORT_DEVELOPER],
        },
        component: () => import('@/pages/reporting/report/index.vue'),
      },
    ],
  },
  {
    path: '/ml/',
    meta: {
      role: [UserRole.ROLE_ML_ENGINEER],
    },
    component: () => import('@/pages/machine-learning/index.vue'),
    children: [
      {
        path: 'experiments',
        name: 'ml-experiments',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
        },
        component: () => import('@/pages/machine-learning/experiments/index.vue'),
      },
      {
        path: 'experiments/:expGuid/runs',
        name: 'ml-experiment-runs',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
          parentName: 'ml-experiments',
        },
        component: () => import('@/pages/machine-learning/runs/index.vue'),
      },
      {
        path: 'experiments/:expGuid/models',
        name: 'ml-experiment-models',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
        },
        component: () => import('@/pages/machine-learning/models/index.vue'),
      },
      {
        path: 'models/:modelGuid/versions',
        name: 'ml-model-versions',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
          parentName: 'ml-experiment-models',
        },
        component: () => import('@/pages/machine-learning/versions/index.vue'),
      },
      {
        path: 'models/:modelGuid/runs/:runGuid',
        name: 'ml-model-run',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
          parentName: 'ml-experiments',
        },
        component: () => import('@/pages/machine-learning/run/index.vue'),
      },
      {
        path: 'models/versions/:versionGuid',
        name: 'ml-model-version',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
          parentName: 'ml-experiment-models',
        },
        component: () => import('@/pages/machine-learning/version/index.vue'),
      },
      {
        path: 'neuro-patterns',
        name: 'ml-neuro-patterns',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
        },
        component: () => import('@/pages/machine-learning/neuro-patterns/index.vue'),
      },
      {
        path: 'neuro-pattern/:guid',
        name: 'ml-neuro-pattern',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
        },
        component: () => import('@/pages/machine-learning/neuro-pattern/index.vue'),
      },
      {
        path: 'settings',
        name: 'ml-settings',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
        },
        component: () => import('@/pages/machine-learning/settings/index.vue'),
      },
      {
        path: 'library',
        name: 'ml-library',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
        },
        component: () => import('@/pages/machine-learning/models-library/index.vue'),
      },
      {
        path: 'analytics',
        name: 'ml-analytics',
        meta: {
          role: [UserRole.ROLE_ML_ENGINEER],
        },
        component: () => import('@/pages/machine-learning/analytics/index.vue'),
      },
    ],
  },
  {
    path: '/etl-constructor/',
    meta: {
      role: [UserRole.ROLE_DATA_ENGINEER],
    },
    component: () => import('@/pages/etl-constructor/index.vue'),
    children: [
      {
        path: 'datasources',
        name: 'etl-datasources',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/datasources/index.vue'),
      },
      {
        path: ':type/storages',
        name: 'storages',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/storages/index.vue'),
      },
      {
        path: 'tasks',
        name: 'etl-tasks',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/tasks/index.vue'),
      },
      {
        path: 'task/:guid',
        name: 'etl-task',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/task/index.vue'),
      },
      {
        path: 'datasource-structure/:guid',
        name: 'datasource-structure',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/datasource-structure/index.vue'),
      },
      {
        path: 'query-builder',
        name: 'query-builder',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/query-builder/index.vue'),
      },
      {
        path: 'models',
        name: 'models',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/data-models/index.vue'),
      },
      {
        path: 'task-instances',
        name: 'task-instances',
        meta: {
          role: [UserRole.ROLE_DATA_ENGINEER],
        },
        component: () => import('@/pages/etl-constructor/task-instances/index.vue'),
      },
    ],
  },
  {
    path: '/security/',
    meta: {
      role: [UserRole.ROLE_MASTER_ADMIN, UserRole.ROLE_REALM_ADMIN],
    },
    component: () => import('./security/index.vue'),
    children: [
      {
        path: 'users',
        name: 'users',
        meta: {
          role: [UserRole.ROLE_MASTER_ADMIN, UserRole.ROLE_REALM_ADMIN],
        },
        component: () => import('./security/users/index.vue'),
      },
      {
        path: 'roles',
        name: 'roles',
        meta: {
          role: [UserRole.ROLE_REALM_ADMIN],
        },
        component: () => import('./security/roles/index.vue'),
      },
      {
        path: 'groups',
        name: 'groups',
        meta: {
          role: [UserRole.ROLE_REALM_ADMIN],
        },
        component: () => import('./security/access-groups/index.vue'),
      },
      {
        path: 'group/:guid',
        name: 'settings',
        meta: {
          role: [UserRole.ROLE_REALM_ADMIN],
        },
        component: () => import('./security/access-groups-settings/index.vue'),
      },
      {
        path: 'users-actions',
        name: 'users-actions',
        meta: {
          role: [UserRole.ROLE_MASTER_ADMIN, UserRole.ROLE_REALM_ADMIN],
        },
        component: () => import('./security/users-actions/index.vue'),
      },
      {
        path: 'users-activity',
        name: 'users-activity',
        meta: {
          role: [UserRole.ROLE_MASTER_ADMIN, UserRole.ROLE_REALM_ADMIN],
        },
        component: () => import('./security/users-activity/index.vue'),
      },
    ],
  },
  {
    path: '/administration/',
    meta: {
      role: [UserRole.ROLE_MASTER_ADMIN, UserRole.ROLE_REALM_ADMIN],
    },
    component: () => import('./administration/index.vue'),
    children: [
      {
        path: 'servers',
        name: 'servers',
        meta: {
          role: [UserRole.ROLE_MASTER_ADMIN],
        },
        component: () => import('./administration/servers/index.vue'),
      },
      {
        path: 'domains',
        name: 'domains',
        meta: {
          role: [UserRole.ROLE_MASTER_ADMIN],
        },
        component: () => import('./administration/domains/index.vue'),
      },
      {
        path: 'system-datasources',
        name: 'system-datasources',
        meta: {
          role: [UserRole.ROLE_REALM_ADMIN],
        },
        component: () => import('@/pages/administration/system-datasources/index.vue'),
      },
    ],
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('./404.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]
