import { Component } from '@vue/runtime-core'
import {
  ref,
  Ref,
  computed,
  defineAsyncComponent,
  ComputedRef,
  AsyncComponentLoader,
  ComponentPublicInstance,
} from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';import extend from 'quasar/src/utils/extend.js';
import { defineStore } from 'pinia'
import BaseUiInterface, { DonePromise, asyncActionOptions, YesPromise, OkPromise } from './BaseUiInterface'
import BiDialog from './helpers/biDialog'
import { LayoutState } from '@/shared/utils/layout-state/useLayoutState'

const ConfirmDialog = defineAsyncComponent(() => import('@/shared/dialogs/ConfirmDialog.vue'))

const notifyCommonOptions = {
  position: 'bottom-right',
  progress: true,
  timeout: 5000,
  actions: [{ icon: 'close', color: 'white', round: true, size: 'xs' }],
}

export const useBaseUi = defineStore('baseUi', (): BaseUiInterface => {
  const $q = useQuasar()

  const _loading = ref(false)
  const loading = computed(() => _loading.value)

  async function hasFormErrors(htmlForm: Ref<HTMLFormElement | null>): Promise<boolean> {
    if (!htmlForm.value) {
      showError('Form not found')
      return true
    }
    const result = await htmlForm.value?.validate(true)
    if (!result) {
      showError($translate.formHasErrors)
      return true
    }
    return false
  }

  function defineAsyncComponentWrapper<
    T extends Component = {
      new (): ComponentPublicInstance
    },
  >(source: AsyncComponentLoader<T>): T {
    return defineAsyncComponent(() => {
      showLoading()
      return source().finally(hideLoading)
    })
  }

  function showSuccess(message: string, options = {}) {
    $q.notify(extend({ message, type: 'positive', ...notifyCommonOptions }, options))
  }

  function showError(message: string, options = {}) {
    $q.notify(extend({ message, type: 'negative', ...notifyCommonOptions }, options))
  }

  function showInfo(message: string, options = {}) {
    $q.notify(extend({ message, type: 'info', ...notifyCommonOptions }, options))
  }

  function showWarning(message: string, options = {}) {
    $q.notify(extend({ message, type: 'warning', ...notifyCommonOptions }, options))
  }

  let loadingCounter = 0
  function showLoading(message = '', options = {}) {
    loadingCounter++
    if (loadingCounter > 1) return
    $q.loading.show(extend({ message }, options))
    _loading.value = true
  }

  function hideLoading(all = false) {
    loadingCounter--
    if (loadingCounter > 0 && !all) return
    if (all) loadingCounter = 0
    $q.loading.hide()
    _loading.value = false
  }

  function reloadPage(url?: string) {
    if (url) {
      window.location.href = url
    } else {
      window.location.reload()
    }
  }

  function asyncHiddenAction(body: () => Promise<any>, layoutState?: ComputedRef<LayoutState>): DonePromise {
    return asyncAction({ body, silent: true, dontShowError: true, layoutState })
  }

  function asyncHiddenActionPromise(body: () => Promise<any>, layoutState?: ComputedRef<LayoutState>): Promise<any> {
    return new Promise((resolve, reject) => {
      asyncAction({ body, silent: true, dontShowError: true, layoutState }).onDone(resolve).onFail(reject)
    })
  }

  function asyncSilentAction(body: () => Promise<any>, layoutState?: ComputedRef<LayoutState>): DonePromise {
    return asyncAction({ body, silent: true, layoutState })
  }

  function asyncSilentActionPromise(body: () => Promise<any>, layoutState?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      asyncAction({ body, silent: true, layoutState }).onDone(resolve).onFail(reject)
    })
  }

  function asyncActionPromise(optionsOrBodyFunc: asyncActionOptions | (() => any)): Promise<any> {
    return new Promise((resolve, reject) => {
      asyncAction(optionsOrBodyFunc).onDone(resolve).onFail(reject)
    })
  }

  function asyncPromise(func: () => any): Promise<any> {
    return new Promise((resolve, reject) => {
      const result = func()
      if (result && typeof result === 'object' && 'onDone' in result) {
        result.onDone(resolve).onFail(reject)
      } else {
        resolve(undefined)
      }
    })
  }

  function asyncAction(optionsOrBodyFunc: asyncActionOptions | (() => any)): DonePromise {
    const onFail: any[] = []
    const onDone: any[] = []
    const onDismiss: any[] = []
    const options: asyncActionOptions =
      typeof optionsOrBodyFunc === 'function' ? { body: optionsOrBodyFunc } : optionsOrBodyFunc
    const { silent, loadingOptions, dontShowError } = options
    const { message, ...restLoadingOptions } = loadingOptions || {}
    const layoutState = options.layoutState?.value
    if (!silent) showLoading(message, restLoadingOptions)
    if (layoutState) {
      layoutState.setPending(true)
      layoutState.setError(null)
    }
    let result: any
    let done = false
    options
      .body()
      .then(bodyResult => {
        result = bodyResult
        done = true
      })
      .catch((err: Error) => {
        if (!dontShowError) {
          showError($translate.loadingError, { caption: err.message })
        }
        onFail.forEach(func => func(err.message))
        if (layoutState) layoutState.setError(err.message)
      })
      .finally(() => {
        if (!silent) hideLoading()
        if (layoutState) layoutState.setPending(false)
        if (done) onDone.forEach(func => func(result))
        onDismiss.forEach(func => func())
      })
    const resultObject = {
      onDone: (onDoneFunc?: () => void) => {
        if (onDoneFunc) onDone.push(onDoneFunc)
        return resultObject
      },
      onFail: (onFailFunc?: (err?: string) => void) => {
        if (onFailFunc) onFail.push(onFailFunc)
        return resultObject
      },
      onDismiss: (onDismissFunc?: () => void) => {
        if (onDismissFunc) onDismiss.push(onDismissFunc)
        return resultObject
      },
    }
    return resultObject
  }

  function asyncActionConfirm(
    text: string,
    options: asyncActionOptions | (() => any),
    uiOptions: any = {},
  ): DonePromise {
    let onDone: any = null
    let onFail: any = null
    let onDismiss: any = null
    const { skip, ...restUiOptions } = uiOptions
    if (skip) {
      asyncAction(options).onDone(onDone).onFail(onFail).onDismiss(onDismiss)
    } else {
      showConfirm(text, restUiOptions).onYes(() => {
        asyncAction(options).onDone(onDone).onFail(onFail).onDismiss(onDismiss)
      })
    }
    const chain = {
      onDone: (func: () => void) => {
        onDone = func
        return chain
      },
      onFail: (func: () => void) => {
        onFail = func
        return chain
      },
      onDismiss: (func: () => void) => {
        onDismiss = func
        return chain
      },
    }
    return chain
  }

  function showConfirm(text: string, uiOptions: any = {}): YesPromise {
    let onYes: any = null
    let onNo: any = null

    const { skip, ...restUiOptions } = uiOptions

    if (skip) {
      Promise.resolve().then(() => {
        onYes?.()
      })
    } else {
      openDialog({
        component: ConfirmDialog,
        componentProps: { text, ...restUiOptions },
      })
        .onOk(() => onYes?.())
        .onCancel(() => onNo?.())
    }

    const resultObj = {
      onYes: (onYesFunc: any) => {
        onYes = onYesFunc
        return resultObj
      },
      onNo: (onNoFunc: any) => {
        onNo = onNoFunc
        return resultObj
      },
    }

    return resultObj
  }

  function openDialog(payload: any): OkPromise {
    return BiDialog.open(payload)
  }

  function openDialogPromise(payload: any): Promise<any> {
    return new Promise(resolve => {
      openDialog(payload)
        .onOk(resolve)
        .onCancel(() => resolve(null))
    })
  }

  function getDialogApi(): { onDialogOK: (result: any) => void; onDialogCancel: () => void; onDialogHide: () => void } {
    return BiDialog.getApi()
  }

  return {
    loading,
    showInfo,
    showError,
    showWarning,
    showSuccess,
    showLoading,
    hideLoading,
    reloadPage,
    asyncAction,
    asyncActionPromise,
    asyncHiddenAction,
    asyncHiddenActionPromise,
    asyncSilentAction,
    asyncSilentActionPromise,
    showConfirm,
    asyncActionConfirm,
    openDialog,
    openDialogPromise,
    getDialogApi,
    hasFormErrors,
    asyncPromise,
    defineAsyncComponentWrapper,
  }
})
