import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/pages'
import { EmitterEvent, useEventEmitter } from '@/shared/utils/event-emitter/useEventEmitter'
import { getAuthorities, UserPermission } from '@/store/auth/user-store/permissions'
import { UserAuthority, UserRole } from '@/shared/types/roles'

const eventEmitter = useEventEmitter()
const guestPages = ['login', 'reset-password', 'verify-password', 'widget-embed', 'widget-export']

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const isLogged = Boolean(localStorage.getItem('access_token') && localStorage.getItem('realmGuid') !== '')

  if (!isLogged && !guestPages.includes((to.name as string) || '')) {
    window.location.href = '/login'
    return
  }

  if (!to.meta?.authority && !to.meta?.permission && !to.meta?.role) return next()

  const goPage404 = () => next({ name: 'not-found' })

  const userData = JSON.parse(localStorage.getItem('userData') || '')
  const realmGuid = localStorage.getItem('realmGuid') || userData?.realms?.[0]?.guid
  const userAuthorities: string[] = userData?.realms?.find((item: any) => item.guid === realmGuid)?.authorities || []
  const userRoles: any[] = userData?.realms?.find((item: any) => item.guid === realmGuid)?.roles || []

  const authorities: UserAuthority[] = (to.meta as any)?.authority || []
  if (authorities.some(key => !userAuthorities.includes(key))) return goPage404()

  const permissions: UserPermission[] = (to.meta as any)?.permission || []
  if (permissions.some(permKey => getAuthorities(permKey).some(key => !userAuthorities.includes(key))))
    return goPage404()

  const roles: UserRole[] = (to.meta as any)?.role || []
  if (roles.length > 0 && userRoles.every(role => !roles.includes(role.name))) return goPage404()

  next()
})

eventEmitter.on(EmitterEvent.JWT_EXPIRED_OR_INVALID, () => {
  if (window.location.pathname === '/login') return
  window.location.href = '/login'
})

function initUserData() {
  if (localStorage.getItem('userData')) return
  const realmGuid = localStorage.getItem('realmGuid')
  if (!realmGuid) return
  localStorage.setItem('userData', `{"realms":[{"guid":"${realmGuid}","authorities":[]}]}`)
}

initUserData()

export { router }
